import React, { useState, useEffect } from 'react';
import "./RelationshipEditor.css"

const RelationshipForm = ({ selectedObject, models, schemas, onSave }) => {

    const [targetObjectId, setTargetObjectId] = useState('');
    const [filteredModels, setFilteredModels] = useState([]);

    useEffect(() => {
        if (selectedObject) {
            const sourceModel = selectedObject['$metadata']["$model"];
            const filteredSchemas = schemas.filter(s => s['@id'] === sourceModel);
            const possibleRelationshipTargets = filteredSchemas[0].contents.filter(c => c["@type"] === "Relationship").map(c => c["target"]);
            if (possibleRelationshipTargets.length > 0) {
                let filteredModels = models.filter(model => possibleRelationshipTargets.includes(model['$metadata']["$model"]));
                const extendedSchemas = schemas.filter(s => "extends" in s && s.extends.some(s => possibleRelationshipTargets.includes(s)));
                const schemaIds = new Set(extendedSchemas.map(es => es["@id"]));
                const extendedModels = models.filter(m => schemaIds.has(m['$metadata']["$model"]));

                if (extendedModels && extendedModels.length > 0) {
                    filteredModels = [...filteredModels, ...extendedModels];
                }
                setFilteredModels(filteredModels);
            } else {
                setFilteredModels([]);
            }
        }
    }, [models, selectedObject, schemas]);

    const handleRelationshipChange = (e) => {
        var selectedValue = e.target.value;
        if (selectedValue) {
            setTargetObjectId(selectedValue)
        }
    };

    const GenerateRelationShipName = (targetObjectId) => {
        var targetObject = models.filter(m => m["$dtId"] === targetObjectId);
        var type = targetObject[0]["Type"];
        return `${type}s`;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        var relationshipName = GenerateRelationShipName(targetObjectId);
        const relationshipObject = {
            "$sourceId": selectedObject["$dtId"],
            "$targetId": targetObjectId,
            "$relationshipName": relationshipName // Derive this based on selected target object
        };

        const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/relationship`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(relationshipObject)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Relationship added successful:', data);
            // Handle successful command execution here (e.g., show a notification)
        } catch (error) {
            console.error('Error adding relationship:', error);
            // Handle errors here (e.g., show an error message)
        }

        // onSave(relationshipObject);
    };

    return (
        <div>
            <h4>
                Add Relationship
            </h4>
            <form onSubmit={handleSubmit} className="relationship-form">
                <select id="targetObject" value={targetObjectId} onChange={handleRelationshipChange}>
                    <option value="">Select a Target</option>
                    {filteredModels.map(model => (
                        <option key={model['$dtId']} value={model['$dtId']}>
                            {model['Name']}
                        </option>
                    ))}
                </select>
                <button type="submit" className="save-btn">Save</button>
            </form>
        </div>
    );
};

export default RelationshipForm;
