import React, { useState, useEffect } from 'react';

const ObjectEditor = ({ schemas, onSave }) => {
  const [objectInstance, setObjectInstance] = useState({});
  const [properties, setProperties] = useState([]);
  const extractEditableProperties = (schemas) => {
    let properties = [];
  
    // Assuming schemas is an array of DTDL interfaces
    schemas.forEach((schema) => {
      schema.contents.forEach((content) => {
        if (content['@type'] === 'Property') {
          properties.push({
            name: content.name,
            schema: content.schema,
            writable: content.writable !== undefined ? content.writable : true, // Assuming writable if not specified
          });
        }
      });
    });
  
    return properties;
  };

  const convertValue = (name, value) => {
    let convertedValue = value;
    let fieldSchema = properties.filter(op => op["name"] === name)[0].schema;
    if (fieldSchema) {
        switch (fieldSchema) {
            case 'double':
            case 'float':
            case 'long':
                convertedValue = parseFloat(value);
                convertedValue = isNaN(convertedValue) ? 0 : convertedValue;
                break;
            case 'integer':
                convertedValue = parseInt(value, 10);
                break;
            case 'boolean':
                convertedValue = value === 'true';
                break;
            case 'dateTime':
                // Assuming ISO 8601 format; no conversion needed as it's already a string
                break;
            // Handle additional schema types as needed
            default:
                // For string and other unhandled types; no conversion needed
                break;
        }
    }
    return convertedValue;
};
  
  useEffect(() => {
    if (schemas) {
      const editableProperties = extractEditableProperties(schemas);
      setProperties(editableProperties);

      // Initialize objectInstance with properties
      let newInstance = {};
      editableProperties.forEach(prop => {
        newInstance[prop.name] = ''; // Initialize with empty string or based on schema type
      });
      setObjectInstance(newInstance);
    }
  }, [schemas]);

  const handleChange = (e, propName) => {
    var convertedValue = convertValue(propName, e.target.value )
    setObjectInstance({ ...objectInstance, [propName]: convertedValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(objectInstance);
  };

  return (
    <form onSubmit={handleSubmit}>
      {properties.map((prop) => (
        <div key={prop.name}>
          <label>
            {prop.name}:
            <input
              type="text" // You might want to vary this based on prop.schema
              value={objectInstance[prop.name] || ''}
              onChange={(e) => handleChange(e, prop.name)}
              disabled={!prop.writable}
            />
          </label>
        </div>
      ))}
      <button type="submit">Save Instance</button>
    </form>
  );
};

export default ObjectEditor;