import React, { useState, useEffect, useRef } from 'react';
import "./PropertyPanel.css";

const PropertiesEditor = ({ selectedObject, schemas }) => {
    const [formState, setFormState] = useState({});
    const [objectProperties, setObjectProperties] = useState([]);
    const formRef = useRef();
    const inputRefs = useRef(null);
    let formFields = useRef();

    const styles = {
        container: {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "5px",
          maxWidth: "400px",
          margin: "auto",
          borderRadius: "8px",
        },
        select: {
          padding: "5px",
          margin: "5px 0",
          borderRadius: "5px",
          border: "1px solid #ccc",
        },
        input: {
          padding: "5px",
          margin: "5px 0",
          width: "calc(100% - 22px)", // Accounting for padding and border
          borderRadius: "5px",
          border: "1px solid #ccc",
        },
        button: {
          padding: "10px 20px",
          margin: "10px 0",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        },
        fieldContainer: {
          width: "100%",
          marginBottom: "10px",
          marginTop: "10px",
        },
        label: {
          marginBottom: "5px",
          display: "block",
        },
      };
    const getAllProperties = (schema, allSchemas) => {
        let properties = [];

        // Direct properties from the current schema
        const directProperties = schema.contents.filter(item => item['@type'] === 'Property' || item['@type'] === 'Telemetry');
        properties = properties.concat(directProperties);

        // Recursively add properties from extended schemas
        if (schema.extends) {
            schema.extends.forEach(extendId => {
                const parentSchema = allSchemas.find(s => s['@id'] === extendId);
                if (parentSchema) {
                    var parentSchemaProperties = getAllProperties(parentSchema, allSchemas);
                    properties = parentSchemaProperties.concat(properties);
                }
            });
        }

        return properties;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    useEffect(() => {
        const selectedSchema = schemas.filter(s => s['@id'] === selectedObject['$metadata']["$model"])[0];
        // Initialize form data using properties from the selected schema and its extensions
        const properties = getAllProperties(selectedSchema, schemas);
        setObjectProperties(() => properties);
        const initialFormData = properties.reduce((acc, prop) => {
            acc[prop.name] = prop;
            prop.value = selectedObject[prop.name]
            return acc;
        }, {});
        setFormState(() => initialFormData);

        formFields.current = properties.map((field, index) => (
            <div style={styles.container} key={index}>
            <label style={styles.label}>{field.displayName}: </label>
            {renderInputField(selectedSchema, field)}
          </div>
        ));
    }, [selectedObject]);

    useEffect(() => {
        inputRefs.current = Array.from(formRef.current.elements).reduce((acc, element) => {
            var elementName = element.name;
            if (elementName) { // Only include elements with a name attribute\
                element.value = formState[element.name].value;
                acc[element.name] = element
            }
            return acc;
        }, {});


    }, [formState])

    const convertValue = (name, value, schema) => {
        let convertedValue = value;
        let fieldSchema = schema
        if (fieldSchema) {
            switch (fieldSchema) {
                case 'double':
                case 'float':
                case 'long':
                    convertedValue = parseFloat(value);
                    convertedValue = isNaN(convertedValue) ? 0 : convertedValue;
                    break;
                case 'integer':
                    convertedValue = parseInt(value, 10);
                    break;
                case 'boolean':
                    convertedValue = value === 'true';
                    break;
                case 'dateTime':
                    // Assuming ISO 8601 format; no conversion needed as it's already a string
                    break;
                // Handle additional schema types as needed
                case 'enum':
                    debugger;
                    // Assuming ISO 8601 format; no conversion needed as it's already a string
                    break;
                default:
                    // For string and other unhandled types; no conversion needed
                    break;
            }
        }
        return convertedValue;
    };

    const renderInputField = (schema, field) => {

        if (!field) {
            return;
        }

        let fieldCustomSchema;
        if (schema.schemas) {
            fieldCustomSchema = schema?.schemas?.filter(
                (s) => s["@id"] === field.schema
            );
        }
        if (field.schema === "dateTime") {
            return (
                <input
                    style={styles.input}
                    type="datetime-local"
                    name={field.name}
                    onChange={convertValue}
                />
            );
        } else if (field.schema === "double" || field.schema === "integer") {
            return (
                <input
                    style={styles.input}
                    type={"number"}
                    name={field.name}
                    schema={field.schema}
                    onChange={convertValue}
                    step="0.00001"
                />
            );
        } else if (field.schema === "string") {
            return (
                <input
                    style={styles.input}
                    type={"text"}
                    name={field.name}
                    schema={field.schema}
                    onChange={convertValue}
                />
            );
        } else if (field.schema === "boolean") {
            return (
                <>
                    {" "}
                    <select
                        name={field.name}
                        style={styles.select}
                        schema={field.schema}
                        onChange={convertValue}
                    >
                        <option value="">Select a value</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </>
            );
        } else if (
            field.schema["@type"] === "Enum" &&
            field.schema.hasOwnProperty("enumValues")
        ) {
            return (
                <select name={field.name} schema="enum" onChange={convertValue} style={styles.select}>
                    {field.schema.enumValues.map((enumValue) => (
                        <option key={enumValue.enumValue} value={enumValue.enumValue}>
                            {enumValue.name}
                        </option>
                    ))}
                </select>
            );
        } else if (fieldCustomSchema && fieldCustomSchema.length > 0) {
            if (fieldCustomSchema[0]["@type"] === "Enum") {
                return (
                    <select name={field.name} schema="enum" onChange={convertValue} style={styles.select}>
                        <option value="">Select a value</option>
                        {fieldCustomSchema[0].enumValues.map((enumValue) => (
                            <option key={enumValue.name} value={enumValue.name}>
                                {enumValue.name}
                            </option>
                        ))}
                    </select>
                );
            }
        }
        // Additional field types can be handled here
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = Object.keys(inputRefs.current).reduce((acc, key) => {
            acc[key] = convertValue(key, inputRefs.current[key].value);
            return acc;
        }, {});
        var mergedObject = { ...selectedObject, ...data };
        delete mergedObject.children;
        console.log(mergedObject);

        const url = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/instance`;

        fetch(url, {
            method: 'PUT', // Specify the request method
            headers: {
                'Content-Type': 'application/json', // Specify the content type in the header
            },
            body: JSON.stringify(mergedObject)
        })
            .then(response => response.json()) // Parse JSON response into JavaScript object
            .then(data => {
                console.log('Success:', data); // Handle success
            })
            .catch((error) => {
                console.error('Error:', error); // Handle errors
            });

    };

    if (!selectedObject) {
        return <div className="editor-message">Please select an item to edit.</div>;
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} className="properties-editor">
            {formFields.current}
            <button type="submit" className="save-button">Save</button>
        </form>
    );
}


export default PropertiesEditor;
