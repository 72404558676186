import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faWater, faLink, faPuzzlePiece, faFolder, faFile, faTrash, faBell } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import NavBar from './NavBar';
import IconViewModule from "./icons/Module";
import IconPipeValve from "./icons/Valve";
import PropertiesEditor from './Components/PropertyPanel/PropertyEditor';
import DtdlSchemasList from './Components/DTDLPanel';
import ObjectEditor from "./Components/ObjectEditor/ObjectEditor";
import Modal from "./Components/ModalPopup";
import ObjectCreationForm from './Components/ObjectEditor';
import RelationshipForm from './Components/RelationshipEditor/RelationshipForm';
import RelationshipsList from './Components/RelationshipEditor/RelationshipsList';
import CommandPanel from './Components/CommandPanel/index.jsx';

const iconMapping = {
  Equipment: <FontAwesomeIcon icon={faIndustry} />,
  Valve: <IconPipeValve />,
  SubSystem: <FontAwesomeIcon icon={faLink} />,
  Module: <IconViewModule />,
  Alarm: <FontAwesomeIcon icon={faBell} />,
  Category: <FontAwesomeIcon icon={faFolder} />,
  default: <FontAwesomeIcon icon={faFile} />,
};

const App = () => {
  const [twins, setTwins] = useState([]);
  const [twinRelationships, setTwinRelationships] = useState([]);
  const [rootItems, setRootItems] = useState([]);
  const [openFolders, setOpenFolders] = useState({});
  const [selectedObject, setSelectedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // For folders
  const [isPanelOpen, setIsPanelOpen] = useState(false); // For the property panel
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [newObjectTemplate, setNewObjectTemplate] = useState(null);
  const [schemas, setSchemas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [relationships, setRelationships] = useState([]);

  const selectItem = (item) => {
    // Only select the item if it's not a category
    if (item.Type !== 'Category') {
      setSelectedItem(item);
    }
  };

  const toggleFolder = (id) => {
    setOpenFolders((prevOpenFolders) => ({
      ...prevOpenFolders,
      [id]: !prevOpenFolders[id]
    }));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const togglePanel = () => setIsPanelOpen(!isPanelOpen);

  useEffect(() => {
    const fetchData = async () => {// Replace 'API_ENDPOINT' with the actual endpoint URL
      const response = await fetch(`${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/graph`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();

      setTwins(() => jsonData.digitalTwins);
      setTwinRelationships(() => jsonData.relationships);

      const twinsById = jsonData.digitalTwins.reduce((acc, twin) => {
        acc[twin.$dtId] = { ...twin, children: [] };
        return acc;
      }, {});

      // Link children to their parents according to relationships
      jsonData.relationships.forEach(({ $sourceId, $targetId }) => {
        if (twinsById[$sourceId] && twinsById[$targetId]) {
          twinsById[$sourceId].children.push(twinsById[$targetId]);
        }
      });

      // Categorize children by Type under each parent
      const categorizeChildren = (twin) => {
        if (twin.children.length > 0) {
          const categorizedChildren = twin.children.reduce((acc, child) => {
            const { Type } = child;
            if (!acc[Type]) acc[Type] = [];
            acc[Type].push(child);
            return acc;
          }, {});

          twin.children = Object.entries(categorizedChildren).map(([Type, items]) => ({
            Name: Type,
            Type: 'Category',
            children: items.map(categorizeChildren)
          }));
        }
        return twin;
      };

      // Identify root items (those not targeted by any relationship)
      const roots = Object.values(twinsById).filter(twin => !jsonData.relationships.some(rel => rel.$targetId === twin.$dtId));

      setRootItems(roots);
    };

    fetchData();
  }, []);

  const handleSelectSchema = (schema) => {
    console.log('Selected schema:', schema);
    // Generate a template for a new object based on the schema
    // This is a placeholder; actual implementation will depend on your schema structure
    if (schema) {
      setSelectedSchema(schema);
      // const template = generateObjectTemplateFromSchema(schema, schemas);
      // setNewObjectTemplate(template); // Assuming you have a state 'newObjectTemplate' to hold this
      setIsModalOpen(true);
    }
  };

  const handleSaveNewInstance = (instance) => {
    console.log('Saving new instance', instance);
    // Implement saving logic here, such as updating state or making an API call
  };

  const deleteItem = (item) => {
    console.log(item);
    fetch(`${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object?id=${item["$dtId"]}`, {
      method: "DELETE", // Specify the request method
      headers: {
        "Content-Type": "application/json", // Specify the content type in the header
      }
    })
      .then((data) => {
        console.log("Success:", data); // Handle success
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors
      });

    const updatedTwins = twins.filter((t) => t.$dtId !== item["$dtId"]);

    setTwins(() => updatedTwins);
  }

  const renderFolders = (items, parentId = '') => {
    return <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
      {items.map((item, index) => {
        const itemId = `${parentId}${index}`;
        const isOpen = openFolders[itemId];
        return (
          <li key={itemId}>
            <span
              onClick={() => {
                toggleFolder(itemId);
                if (item.Type !== 'Category') { // Select the item if it's not a category
                  selectItem(item);
                }
              }}
              style={{ cursor: 'pointer', display: "block" }}

            >
              {iconMapping[item.Type] || iconMapping.default} {item.Name}

              {item.Type !== 'Category' && (
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from bubbling up to the parent elements
                    if (window.confirm(`Are you sure you want to delete ${item.Name}?`)) {
                      deleteItem(item); // Proceed with deletion if confirmed
                    }
                  }} // Replace 'deleteItem' with your actual function to handle deletion
                  style={{ marginLeft: '10px', background: "none", display: "inline-block" }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "black", width: "15px", height: "15px" }}
                  />
                </button>
              )}
            </span>
            {isOpen && item.children && item.children.length > 0 && renderFolders(item.children, `${itemId}-`)}
          </li>
        );
      })}
    </ul>
  };


  return (
    <>
      <div className="App">
        <div className="container">
          {newObjectTemplate && <ObjectEditor template={newObjectTemplate} onSave={handleSaveNewInstance} />}
          <div className="leftPanel">
            <h2>DTDL Schema</h2>
            <DtdlSchemasList onFetchSchemas={setSchemas} onSelectSchema={handleSelectSchema} />
            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
              {/* Pass the selected schema to your object creation form or component */}
              <ObjectCreationForm selectedSchema={selectedSchema} allSchemas={schemas} onCompletion={handleModalClose} />
            </Modal>
          </div>
          <div className="rightPanel" style={{ width: "400px" }}>
            <h2>Model Tree</h2>
            {renderFolders(rootItems)}
          </div>
          <div className="leftPanel">
            <div><h2>Properties</h2>
              {selectedObject && <PropertiesEditor selectedObject={selectedObject} schemas={schemas} />}
            </div>
          </div>
          <div className="rightPanel">
            <div>
              <h2>Relationships</h2>
              {selectedObject && (<><RelationshipForm selectedObject={selectedObject} models={rootItems} schemas={schemas} />
                <RelationshipsList selectedItem={selectedObject} twins={twins} twinRelationships={twinRelationships} schemas={schemas} /></>)}
            </div>
          </div>
          <div className="leftPanel">
            <div>
              <h2>Commands</h2>
              {selectedObject && <CommandPanel selectedObject={selectedObject} allSchemas={schemas} />}
            </div>
          </div>
        </div>
      </div></>

  );
};
export default App;
