import React, { useState, useEffect } from "react";
import "./ObjectCreationForm.css";
import ObjectFieldTable from "../ObjectFieldTable";

const getAllProperties = (schema, allSchemas) => {
  let properties = [];

  // Direct properties from the current schema
  const directProperties = schema.contents.filter(
    (item) => item["@type"] === "Property" || item["@type"] === "Telemetry"
  );
  properties = properties.concat(directProperties);

  // Recursively add properties from extended schemas
  if (schema.extends) {
    schema.extends.forEach((extendId) => {
      const parentSchema = allSchemas.find((s) => s["@id"] === extendId);
      if (parentSchema) {
        var parentProperties = getAllProperties(parentSchema, allSchemas);
        properties = parentProperties.concat(properties);
      }
    });
  }

  return properties;
};

const getAllCommands = (schema, allSchemas) => {
  let properties = [];

  // Direct properties from the current schema
  const directProperties = schema.contents.filter(
    (item) => item["@type"] === "Command"
  );
  properties = properties.concat(directProperties);

  // Recursively add properties from extended schemas
  if (schema.extends) {
    schema.extends.forEach((extendId) => {
      const parentSchema = allSchemas.find((s) => s["@id"] === extendId);
      if (parentSchema) {
        properties = properties.concat(
          getAllCommands(parentSchema, allSchemas)
        );
      }
    });
  }

  return properties;
};

const ObjectCreationForm = ({ selectedSchema, allSchemas, onCompletion }) => {
  const [formData, setFormData] = useState({});
  const [objectProperties, setObjectProperties] = useState([]);

  const commandChannelMapping = "CommandChannelMapping";

  useEffect(() => {
    if (!selectedSchema) return;

    // Initialize form data using properties from the selected schema and its extensions
    const properties = getAllProperties(selectedSchema, allSchemas);
    setObjectProperties(() => properties);
    const initialFormData = properties.reduce((acc, prop) => {
      acc[prop.name] = ""; // Initialize as empty; adjust if you need different initial values

      // if (typeof prop.schema === "object") {
      //   if (prop.name === "Channels") {
          
      //     // acc[prop.name] = properties.reduce((acc2, current) => {
      //     //   acc2[current.name] = "";
      //     //   return acc2;
      //     // }, {});

      //     const commands = getAllCommands(selectedSchema, allSchemas);
      //     acc[prop.name] = acc[prop.name] = commands.reduce((acc3, current) => {
      //       acc3[current.name] = "";
      //       return acc3;
      //     }, {});
      //   }
      // }
      return acc;
    }, {});

    setFormData(initialFormData);
  }, [selectedSchema, allSchemas]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let convertedValue = value;
    let fieldSchema = objectProperties.filter((op) => op["name"] === name)[0]
      .schema;
    if (fieldSchema) {
      switch (fieldSchema) {
        case "double":
        case "float":
        case "long":
          convertedValue = parseFloat(value);
          convertedValue = isNaN(convertedValue) ? 0 : convertedValue;
          break;
        case "integer":
          convertedValue = parseInt(value, 10);
          break;
        case "boolean":
          convertedValue = value === "true";
          break;
        case "dateTime":
          // Assuming ISO 8601 format; no conversion needed as it's already a string
          break;
        // Handle additional schema types as needed
        default:
          // For string and other unhandled types; no conversion needed
          break;
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: convertedValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let configData = formData;
    configData["$metadata"] = {
      $model: selectedSchema["@id"],
    };
    configData["Type"] = selectedSchema.displayName["en-US"];
    // Add your logic to handle form submission here
    const url = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/config`;
    const data = configData;

    fetch(url, {
      method: "POST", // Specify the request method
      headers: {
        "Content-Type": "application/json", // Specify the content type in the header
      },
      body: JSON.stringify(data), // Convert the JavaScript object to a JSON string
    })
      .then((response) => response.json()) // Parse JSON response into JavaScript object
      .then((data) => {
        CreateInstance(data["$dtId"]);
        console.log("Success:", data); // Handle success
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors
      });

    onCompletion();
  };

  const CreateInstance = (instanceId) => {
    // Add your logic to handle form submission here
    const url = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/instance?id=${instanceId}`;

    fetch(url, {
      method: "POST", // Specify the request method
      headers: {
        "Content-Type": "application/json", // Specify the content type in the header
      },
    })
      .then((response) => response.json()) // Parse JSON response into JavaScript object
      .then((data) => {
        console.log("Success:", data); // Handle success
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors
      });
  };
  if (!selectedSchema) return <div>No schema selected.</div>;

  const handleUpdateJsonObject = (key, updatedObject) => {
    formData[key] = updatedObject;
  };

  const getPropertyDisplayName = (key) => {
    var name = objectProperties.filter((p) => p.name === key)[0].displayName;
    if(typeof name  === 'object')
    {
      name = name["en-US"]
    }
    return name;
  };
  return (
    <form onSubmit={handleSubmit} className="object-creation-form">
      <h3>Create Object from Schema: {selectedSchema.displayName["en-US"]}</h3>
      {Object.keys(formData).map((key) => {
        const isMapType =
          typeof formData[key] === "object" && formData[key] !== null;
        let element = <></>;

        if (isMapType && Object.keys(formData[key]).length > 0) {
          element = (
            <label>
              {key}:
              <ObjectFieldTable
                fieldName={key}
                objectValue={formData[key]}
                onUpdate={handleUpdateJsonObject}
              />{" "}
            </label>
          );
        } else {
          element = (
            <label>
              {getPropertyDisplayName(key)}:
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
              />
            </label>
          );
        }

        return (
          <div key={key}>
            {key === "Type" ? (
              <label>
                {key}:
                <input
                  type="text"
                  name={key}
                  value={selectedSchema.displayName["en-US"]}
                  onChange={handleChange}
                />
              </label>
            ) : (
              element
            )}
          </div>
        );
      })}
      <button type="submit">Create</button>
      <button onClick={onCompletion}>Cancel</button>
    </form>
  );
};

export default ObjectCreationForm;
