// DtdlSchemasList.js
import React, { useState, useEffect } from "react";
import ReactJson from "react-json-view";
import "./DTDLSchemaPanel.css";
import Modal from "../ModalPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const DtdlSchemasList = ({ onFetchSchemas, onSelectSchema }) => {
  const [schemas, setSchemas] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchSchemas = async () => {
      setIsLoading(true);
      try {
        // Replace 'API_ENDPOINT' with the actual endpoint URL
        const API_URL = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/dtdl`;
        const response = await fetch(API_URL);
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        const data = await response.json();
        setSchemas(data);
        onFetchSchemas(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchemas();
  }, []); // Empty dependency array means this effect runs once on mount

  if (isLoading) return <div>Loading schemas...</div>;
  if (error) return <div>Error fetching schemas: {error}</div>;

  const viewSchema = (id) => {
    setIsModalOpen(true);
    var schema = schemas.find((s) => s["@id"] === id);
    console.log(schema);
    setSelectedSchema(() => schema);
  };

  return (
    <div className="dtdl-schemas-list">
      <ul>
        {schemas.map((schema) => {
          return (
            schema["displayName"] != null && (
              <li key={schema["@id"]} onClick={() => onSelectSchema(schema)}>
                {schema["displayName"]["en-US"]}
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from bubbling up to the parent elements
                    viewSchema(schema["@id"]);
                  }}
                  style={{ marginLeft: "10px", background: "none" }}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{ color: "black", width: "15px", height: "15px" }}
                  />
                </button>
              </li>
            )
          );
        })}
      </ul>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(() => false)}>
        <div style={{ minHeight: "350px", overflow: "auto" }}>
          <ReactJson
            src={selectedSchema}
            theme="monokai"
            collapsed={false}
            displayDataTypes={false}
            displayObjectSize={true}
            enableClipboard={true}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DtdlSchemasList;
