import React from "react";

const ObjectFieldTable = ({ fieldName, objectValue, onUpdate }) => {
  const handleValueChange = (key, newValue) => {
    // Update the specific key within the object
    const updatedObject = { ...objectValue, [key]: newValue };
    onUpdate(key, updatedObject);
  };

  return (
    <div>
      <table>
        <tbody>
          {Object.entries(objectValue).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleValueChange(key, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ObjectFieldTable;
