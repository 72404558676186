import { useEffect, useState } from "react";

const RelationshipsList = ({ selectedItem, twins, twinRelationships }) => {

    const [relationships, setRelationships] = useState([]);
    const [selectedRelationship, setSelectedRelationship] = useState(null);

    useEffect(() => {

        if (selectedItem && twins && twinRelationships) {
            var relationsOfSelectedItem = twinRelationships.filter(tr => tr.$sourceId === selectedItem.$dtId);
            var relatedObjectOfSelectedItem = relationsOfSelectedItem.map(r => {
                return twins.filter(t => t.$dtId === r.$targetId).map(tr => ({ "relation": r, id: r.$relationshipId, "objectName": tr.Name }))[0];
            });

            if (relatedObjectOfSelectedItem.length > 0) {
                setRelationships(() => relatedObjectOfSelectedItem);
            } else {
                setRelationships(() => []);
            }
        }

    }, [selectedItem, twins, twinRelationships])

    const handleRelationshipChange = (e) => {
        var selectedValue = e.target.value;
        if (selectedValue) {
            var filteredRelation = relationships.filter(r => r.id === selectedValue)[0];
            setSelectedRelationship(() => filteredRelation);
        }

        console.log(filteredRelation);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API_BASE}/api/object/relationship`;

        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(selectedRelationship?.relation)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Relationship deleted successful:');
            // Handle successful command execution here (e.g., show a notification)
        } catch (error) {
            console.error('Error deleting relationship:', error);
            // Handle errors here (e.g., show an error message)
        }
    };

    return (
        <div className="relationships-list">
            <h4>Existing Relationships</h4>
            <form onSubmit={handleSubmit} className="relationship-form">
                <select id={selectedRelationship?.id} value={selectedRelationship?.objectName} onChange={handleRelationshipChange}>
                    <option value="">Select a Relation</option>
                    {relationships.map((relationship) => (
                        <option key={relationship.id} value={relationship.id}>
                            {relationship.objectName ? relationship.objectName : "None"}
                        </option>
                    ))}
                </select>
                <button type="submit" className="save-btn">Delete</button>
            </form>
        </div>
    );
};

export default RelationshipsList;
