// icon:view-module | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconViewModule(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M16 5v6h5V5m-11 6h5V5h-5m6 13h5v-6h-5m-6 6h5v-6h-5m-6 6h5v-6H4m0-1h5V5H4v6z" />
    </svg>
  );
}

export default IconViewModule;